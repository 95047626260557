<template>
  <el-card>
    <div class="flex-between">
      <div class="flex-start">
        <el-form
          :inline="true"
          :model="listQuery"
        >
          <el-form-item label="法律">
            <el-select
              v-model="listQuery.lawCode"
              clearable
              @change="getDetailsOptions"
            >
              <el-option
                v-for="(law,k) in lawsOption"
                :key="k"
                :label="law.LawName"
                :value="law.lawCode"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="章节"
          >
            <el-select
              v-model="listQuery.lawDetailsId"
              clearable
              placeholder="请选择章节"
            >
              <el-option
                v-for="(chapter,k) in chapterOption"
                :key="k"
                :label="chapter.labelName"
                :value="chapter.lawDetailsId"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleFilter"
            >
              搜索
            </el-button>
          </el-form-item>
        </el-form>

      </div>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="$router.push({path: '/unscrambleSave'})"
      >
        新增
      </el-button>
    </div>
    <el-table
      v-loading="loading"
      border
      fit
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="法律"
        :show-overflow-tooltip="true"
        prop="lawName"
      />
      <el-table-column
        label="章节"
        :show-overflow-tooltip="true"
        prop="lawDetailsName"
      />
      <el-table-column
        label="解读点"
        :show-overflow-tooltip="true"
        prop="unscramblePoint"
      />
      <!--      <el-table-column-->
      <!--        label="内容"-->
      <!--        :show-overflow-tooltip="true"-->
      <!--        prop="unscrambleContent"-->
      <!--      >-->
      <!--        <template slot-scope="{row}">-->
      <!--          <div v-html="row.unscrambleContent" />-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        label="解读人"
        :show-overflow-tooltip="true"
        prop="unscrambleUser"
      />
      <el-table-column
        label="解读时间"
        prop="unscrambleTime"
      >
        <template slot-scope="{row}">
          {{ row.unscrambleTime ? formatDateStr(row.unscrambleTime) : '' }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="120px"
      >
        <template slot-scope="{row}">
          <el-link
            class="mr10"
            type="primary"
            @click="$router.push({path: '/unscrambleSave', query: { id: row.id}})"
          >
            <el-tooltip
              content="编辑"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>
          <el-link
            class="mr10"
            type="primary"
            @click="contentDialogShow(row)"
          >
            <el-tooltip
              content="内容详情"
              placement="top"
              effect="light"
            >
              <i class="el-icon-document" />
            </el-tooltip>
          </el-link>
          <!--          <el-link-->
          <!--            class="mr10"-->
          <!--            type="primary"-->
          <!--            @click="$router.push({path: '/unscrambleDetail', query: { uId: row.id}})"-->
          <!--          >-->
          <!--            <el-tooltip-->
          <!--              content="最佳实践"-->
          <!--              placement="top"-->
          <!--              effect="light"-->
          <!--            >-->
          <!--              <i class="el-icon-document" />-->
          <!--            </el-tooltip>-->
          <!--          </el-link>-->
          <el-link
            :underline="false"
            type="primary"
            @click="delLaw(row.id)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.pageSize"
      @pagination="getList"
    />
    <el-dialog
      title="内容"
      :visible.sync="dialogVisible"
      width="620px"
      @close="dialogClose"
      @open="dialogOpen"
    >
      <div style="white-space:pre-line;max-height: 300px" v-html="unscrambleContent" />
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">
          取 消
        </el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import { error, success } from '@core/utils/utils'
import {
  LawUnscramblePage,
  DeleteLawUnscrambleByIds,
  GetLawOptions,
  GetDetailsOptions,
} from '@/api/knowledgeBase/laws.js'
import { get } from '@/libs/axios'

export default {
  components: {
    pagination,
  },

  data() {
    return {
      loading: false,
      dialogVisible: false,
      unscrambleContent: '',
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        searchStr: '',
        lawCode: '',
        lawDetailsId: '',
      },
      tableData: [],
      lawsOption: [],
      chapterOption: [],
    }
  },
  created() {
    this.getLawOptions()
    this.getList()
  },
  methods: {
    contentDialogShow(row) {
      this.unscrambleContent = row.unscrambleContent
      this.dialogVisible = true
    },
    getLawOptions() {
      GetLawOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.lawsOption = resData.data
        }
      })
    },
    getDetailsOptions() {
      GetDetailsOptions({ lawCode: this.listQuery.lawCode }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.listQuery.lawDetailsId = ''
          this.chapterOption = resData.data
        }
      })
    },
    delLaw(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        DeleteLawUnscrambleByIds({ ids: [id] }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getList() {
      this.loading = true
      LawUnscramblePage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
